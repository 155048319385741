import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Ending from "./pages/Ending";
import ScreenQuestion from "./pages/ScreenQuestion";
import Success from "./pages/Success";
import ProfilerQuesion from "./pages/ProfilerQuesion";
import Error from "./pages/Error";
import Start from "./pages/Start";
import {
  LANDING_ROUTE,
  SCREENER_ROUTE,
  PROFILING_ROUTE,
  START_ROUTE,
  SUCCESS_ROUTE,
  ENDING_ROUTE,
  ERROR_ROUTE,
  TERMINATE,
  SECURITY_TERMINATE,
  OVER_QUOTA,
  CAMPAIGN_ROUTE,
} from "./constants/routes.constants";
import Terminate from "./pages/Terminate";
import OverQuota from "./pages/OverQuota";
import SecurityTerminate from "./pages/SecurityTerminate";
import Landing from "./pages/Landing";
import Campaign from "./pages/Campaign";
import { Button, ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from "react";

function App() {
  const innerTheme = createTheme({
    palette: {
      primary: {
        main: "#326FA8",
      },
    },
  });
  const [theme, setTheme] = useState(innerTheme);

  useEffect(() => {

    //call api to get theme

    let defaultTheme = {
      palette: {
        primary: {
          main: "#FDA12B",
        },
        text: {
          main: "rgba(51,51,51,0.87)",
        },
        body: {
          main: "#f2f2f2",
        },
        questionBody: {
          main: "#ffffff",
        },
      },
    };
    setTheme(createTheme(defaultTheme));
    const root = document.documentElement;
    root.style.setProperty("--primary", defaultTheme.palette.primary.main);
    root.style.setProperty("--body", defaultTheme.palette.body.main);
    root.style.setProperty("--questionBody", defaultTheme.palette.questionBody.main);
    root.style.setProperty("--text", defaultTheme.palette.text.main);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App h-screen">
        <BrowserRouter>
          <Routes>
            <Route path={LANDING_ROUTE} element={<Landing />} />
            <Route path={CAMPAIGN_ROUTE} element={<Campaign />} />
            <Route path={SCREENER_ROUTE} element={<ScreenQuestion />} />
            <Route path={PROFILING_ROUTE} element={<ProfilerQuesion />} />

            {/* start */}
            <Route path={START_ROUTE} element={<Start />} />
            <Route path={SUCCESS_ROUTE} element={<Success />} />
            <Route path={ENDING_ROUTE} element={<Ending />} />
            <Route path={ERROR_ROUTE} element={<Error />} />
            <Route path={TERMINATE} element={<Terminate />} />
            <Route path={OVER_QUOTA} element={<OverQuota />} />
            <Route path={SECURITY_TERMINATE} element={<SecurityTerminate />} />
            <Route path="*" element={<Navigate to={ERROR_ROUTE} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
