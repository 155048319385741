import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/ClipLoader";
import useQueryParams from "../utils/query.params";
import {
  ERROR_ROUTE,
  SCREENER_ROUTE,
  START_ROUTE,
} from "../constants/routes.constants";
import RespondentFlowApi from "../services/respondentFlow.api";
import Error from "./Error";

const Landing = () => {
  const [Loader] = useState(true);
  const [ErrorMessage, setErrorMessage] = useState("Loading");
  const [paramActive, setParamActive] = useState(false);
  let params = {};
  useQueryParams().forEach((value, key) => {
    params[key] = value;
  });
  useEffect(() => {
    if (params) {
      if (paramActive === false) setParamActive(true);
    }
    // eslint-disable-next-line
  }, [params]);

  let navigate = useNavigate();

  useEffect(() => {
    if (paramActive === true) {
      try {
        params["url"] = window.location.href;
        RespondentFlowApi.post("/v1/ending", params)
          .then(async (data) => {
            if (data.result === "not_ok") {
              navigate(`${ERROR_ROUTE}?token=${params.token}`);
              return;
            } else if (data.hasError) {
              setErrorMessage(data.messages[0]);
              return;
            } else {
              if (data.isInternalRedirect) {
                let obj = {};
                obj.traceId = data.traceId;
                obj.paramList = data.paramList;
                obj.sessionUid = data.sessionUid;
                obj.surveyUid = data.surveyUid;
                obj.vendorUid = data.vendorUid;
                obj.vendorToken = data.vendorToken ?? "";
                obj.token = data.token;
                obj.tokenId = data.tokenId;
                obj.userUid = data.userUid;
                obj.userId = data.userId;
                obj.isEnding = true;
                sessionStorage.clear();
                sessionStorage.setItem("params", JSON.stringify(obj));

                navigate(`${START_ROUTE}?token=${obj.token}`);
              } else {
                sessionStorage.clear();
                window.location.href = `${data.redirectUrl}`;
              }
            }
          })
          .catch((e) => {
            console.error(e);
            navigate(ERROR_ROUTE);
          });
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
    // eslint-disable-next-line
  }, [paramActive]);

  return (
    <>
      {Loader ? (
        ErrorMessage === "Loading" ? (
          <div
            className="flex justify-center items-center flex-col h-screen "
            style={{
              backgroundColor: "var(--body)",
            }}
          >
            <BounceLoader
              color="var(--primary)"
              loading={true}
              size={60}
              textalign="center"
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <span
              style={{
                fontWeight: "700",
                marginTop: "20px",
                display: "block",
                textalign: "center",
                color: "var(--primary)",
              }}
            >
              {ErrorMessage}
            </span>
          </div>
        ) : (
          <Error ErrorMessage={ErrorMessage} />
        )
      ) : (
        <>
          <div className="flex  justify-center">
            <img
              src="https://img.icons8.com/bubbles/256/man-with-a-clock.png"
              alt=""
            />
          </div>
        </>
      )}
    </>
  );
};

export default Landing;
